/** @jsxImportSource @emotion/react  */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
import { FC, useEffect } from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useForm, FormProvider } from "react-hook-form";

import { Link as Linker, animateScroll as scroll } from "react-scroll";

import { Question } from "./components/Question";
import Const from "./const";
import axios from "axios";

const Form = () => {
  const methods = useForm();
  const [result, setResult] = useState("");

  const handleSubmit = methods.handleSubmit((data) => {
    //フォームデータをAWSに送信し、OKならばqrcodeページに遷移する
    let form = { form_id: process.env.REACT_APP_EVENT_ID || "" };
    //静的です。修正必要。
    let form_data = {
      form_id: process.env.REACT_APP_EVENT_ID || "",
      drink_num: process.env.REACT_APP_MAX_DRINK_NUM || "",
      question1: data.question1,
      question2: data.question2,
      question3: data.question3,
      question4: data.question4,
      question5: data.question5,
      question6: data.question6,
      question7: data.question7,
    };

    axios
      .post(submitURL, form_data)
      .then((response) => {
        console.log("response: " + JSON.stringify(response));
        console.log("user_id" + response.data.body.user_id);
        let form_id = response.data.body.form_id;
        let user_id = response.data.body.user_id;
        let eka_data = response.data.body.eka_data;
        navigate("/qrcode", {
          state: { form_id: form_id, user_id: user_id, eka_data: eka_data },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  });

  const submitURL = process.env.REACT_APP_SUBMIT_URL || "";

  const navigate = useNavigate();

  const questionList = Const.QUESTION_LISTS;

  const questionNum = questionList.length; // 問題数

  useEffect(() => {
    //scroll.scrollToTop();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack
      spacing={2}
      css={content}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <FormProvider {...methods}>
        <form css={formScroll} onSubmit={handleSubmit}>
          {questionList.map((question, index) => {
            return (
              <Question
                type={question.type}
                label={question.label}
                example={question.example}
                required={question.required}
                number={question.id}
                key={index}
                options={question.options}
                count={questionNum}
              />
            );
          })}
        </form>
      </FormProvider>
    </Stack>
  );
};

const questionStatus = css`
  position: fixed;
  width: 100%;
  text-align: right;
  margin-top: 2rem;
  top: 0;
  right: 3rem;
`;

const formScroll = css`
  position: relative;
  width: 100%;
  overflow-y: auto;
  margin-left: 0rem !important;
`;

const form_text = css`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #adadad;
  background: none;
  width: 83%;
  font-size: 1rem;
  color: #ffffff;
  &::placeholder {
    color: #adadad;
  }
  &:focus {
    border-bottom: 1px solid #ffff33;
    outline: none;
  }
`;

const form_select = css`
  border: solid 0.2rem #9fcb67;
  border-radius: 0.4rem;
  background: none;
  width: 12rem;
  height: 3rem;
  color: white;
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
`;

const move_btn = css`
  margin-top: 1rem;
`;

const form_h2 = css`
  margin-bottom: 0;
  font-size: 1.1rem;
`;

const form_h3 = css`
  margin-top: 0;
  color: #adadad;
  font-size: 0.9rem;
`;

const section = css`
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  padding: 21rem 0 0 2rem;
`;

const content = css``;

export default Form;
