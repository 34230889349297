/** @jsxImportSource @emotion/react  */
import React from "react";

import { css } from "@emotion/react";
import { useForm, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//const { decycle, encycle } = require("json-cyclic");

import Button from "@mui/material/Button";

import { Link as Linker, animateScroll as scroll } from "react-scroll";

type Props = {
  type: string;
  label: string;
  example: string;
  required: boolean;
  number: number;
  options: Array<string>;
  count: number;
};

function onTextButtonClick() {
  console.log("onTextButtonClick");
}

const Question = (props: Props) => {
  //const { register, handleSubmit } = useForm();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const sectionId = "question" + props.number;
  const nextSection = "question" + (props.number + 1);
  const backSection = "question" + (props.number - 1);

  React.useEffect(() => {
    //console.log("test" + JSON.stringify(errors));
  }, [errors]);

  if (props.type === "text") {
    return (
      <section id={sectionId} css={[section]}>
        <ErrorMessage
          errors={errors}
          name="favorites"
          render={({ message }) => <p>{message}</p>}
        />
        <div css={content}>
          <h2 css={form_h2}>{props.label}</h2>
          <h3 css={form_h3}>{props.example}</h3>
          <input
            css={form_text}
            type="text"
            {...register("from", { required: true })}
            placeholder="入力してください"
          />
          <div css={move_btn}>
            {props.number === props.count && (
              <Button variant="contained" type="submit">
                送信
              </Button>
            )}

            {props.number !== props.count && (
              <Linker to={nextSection} spy={true} smooth={true} duration={500}>
                <Button onClick={onTextButtonClick} variant="contained">
                  次へ
                </Button>
              </Linker>
            )}

            {props.number !== 1 && (
              <Linker to={backSection} spy={true} smooth={true} duration={500}>
                <Button>戻る</Button>
              </Linker>
            )}
          </div>
        </div>
      </section>
    );
  } else if (props.type === "select") {
    return (
      <section id={sectionId} css={[section]}>
        {!!errors.department && <p>{errors.department.message}</p>}
        <div css={content}>
          <h2 css={form_h2}>{props.label}</h2>
          <select
            css={form_select}
            /*isError={!!errors.department}*/
            {...register(sectionId, { required: props.required })}
          >
            <option value="">Select...</option>
            {props.options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
          <div css={move_btn}>
            {props.number === props.count && (
              <Button variant="contained" type="submit" /*css={submitButton}*/>
                アンケートに回答
              </Button>
            )}

            {props.number !== props.count && (
              <Linker to={nextSection} spy={true} smooth={true} duration={500}>
                <Button variant="contained">次へ</Button>
              </Linker>
            )}

            {props.number !== 1 && (
              <Linker to={backSection} spy={true} smooth={true} duration={500}>
                <Button>戻る</Button>
              </Linker>
            )}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section id={sectionId} css={[section]}>
        <div css={content}>
          <h2 css={form_h2}>{props.label}</h2>
          <h3 css={form_h3}>{props.example}</h3>
          <input
            css={form_text}
            type="text"
            {...register("from", { required: true })}
            placeholder="入力してください"
          />
          <div css={move_btn}>
            {props.number === props.count && (
              <Button variant="contained" type="submit">
                送信
              </Button>
            )}

            {props.number !== props.count && (
              <Linker to={nextSection} spy={true} smooth={true} duration={500}>
                <Button variant="contained">次へ</Button>
              </Linker>
            )}

            {props.number !== 1 && (
              <Linker to={backSection} spy={true} smooth={true} duration={500}>
                <Button>戻る</Button>
              </Linker>
            )}
          </div>
        </div>
      </section>
    );
  }
};

const form_text = css`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #adadad;
  background: none;
  width: 83%;
  font-size: 1rem;
  color: #ffffff;
  &::placeholder {
    color: #adadad;
  }
  &:focus {
    border-bottom: 1px solid #ffff33;
    outline: none;
  }
`;

const form_select = css`
  border: solid 0.2rem #9fcb67;
  border-radius: 0.4rem;
  background: none;
  width: 12rem;
  height: 3rem;
  color: white;
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
`;

const move_btn = css`
  margin-top: 1rem;
`;

const form_h2 = css`
  margin-bottom: 0;
  font-size: 1.1rem;
`;

const form_h3 = css`
  margin-top: 0;
  color: #adadad;
  font-size: 0.9rem;
`;

const section = css`
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  padding: 21rem 0 0 2rem;
`;

const content = css``;

/*const submitButton = css`
  background: #4169e1;
  color: white;
`;*/

export { Question };
