/** @jsxImportSource @emotion/react  */
import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import { Global, css } from "@emotion/react";

import Home from './Home';
import About from './About';
import Qrcode from './Eka';
import Form from './Form';

const App = () => {
    return (
        <HashRouter>
            <Global styles={global} />
            <div css={appCss}>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="form" element={<Form/>} />
                    <Route path="qrcode" element={<Qrcode/>} />
                    <Route path="about" element={<About/>} />
                </Routes>
            </div>
            <footer><a href="https://nomasse.jp" target="_blank" rel="noopener noreferrer">Nomasse</a><br />Copyright &copy;2021 <a href="https://www.sync.co.jp/" target="_blank" rel="noopener noreferrer">SYNC, Inc.</a> All Rights Reserved.</footer>
        </HashRouter>
    )
}

const global = css`
    h1 {
        font-size: calc(16px + 2vmin);
    }
    h2 {
        font-size: calc(14px + 2vmin);
    }
    footer {
        padding-top: 4%;
        padding-bottom: 4%;
        background-color: white;
        text-align: center;
        color: black;
    }
`;

const appCss = css`
    padding-bottom: 20px;
`;

export default App;
